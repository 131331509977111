<!--TODO
1 - variantes -->
<template>
  <v-layout row wrap>
    <v-col cols="12" sm="6" md="6">
      <div v-if="baseUrl && item && item.image">
        <v-img v-bind:src="baseUrl + item.image" aspect-ratio="1.48"> </v-img>
      </div>
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <h1 class="display-1 catalog-item-title">{{ item.description }}</h1>
      <h2>{{ item.code }}</h2>

      <h1>
        <span v-if="item.price" class="primary--text">{{ item.price }}&nbsp;{{ currencySymbol }}</span>
      </h1>

      <div v-if="item.price" class="text-xs-center pt-5">
        <v-btn v-if="item.price" large color="primary" @click="addToCart(item.id)" class="mr-0">
          <v-icon left small>fal fa-shopping-cart</v-icon>
          {{ $vuetify.lang.t("$vuetify.add") }}
        </v-btn>
      </div>
    </v-col>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CatalogItemDetail",
  data() {
    return {
      item: {},
    };
  },
  methods: {
    getContentToDisplay() {
      this.item_identifier = parseInt(this.$route.params.id);

      // this.item = this.$store.getters["items/get"](this.item_identifier);
      this.item = this.$store.dispatch("items/get", this.item_identifier);
    },
    /**
     * Adding a new Item to the Cart, which is stored on the local storage
     * @param itemId
     */
    addToCart: function (itemId) {
      this.$store.dispatch("cart/addToCart", itemId);
    },
  },
  computed: {
    ...mapGetters({
      currencySymbol: "configs/currencySymbol",
      baseUrl: "configs/apiImgBaseUrl",
    }),
  },
  mounted() {
    this.getContentToDisplay();
  },
};
</script>

<style scoped></style>
